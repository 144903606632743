import github from '../assets/images/github-icon.svg'
import linkedin from '../assets/images/linkedin-icon.svg'
import kaggle from '../assets/images/kaggle-icon.svg'




export default [
    {
        id: 3,
        title: "Github",
        icon: github,
        linkTo: true,
        path: "https://github.com/cheryllg"
    },
    {
        id: 4,
        title: "LinkedIn",
        icon: linkedin,
        linkTo: true,
        path: "https://www.linkedin.com/in/cheryll-g-811854125"
    },
    {
        id: 5,
        title: "Kaggle",
        icon: kaggle,
        linkTo: true,
        path: "https://www.kaggle.com/CheryllGunawan"
    },
];
