import gmail from '../assets/images/gmail.svg'
import linkedin from '../assets/images/linkedin.svg'

export default [
    {
        id: 1,
        title: "cheryllgunawan@gmail.com",
        icon: gmail,
        linkTo: false,
    },
];
